/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAxOTRkMSIsIj9lcjwjYjNkZmYxIiwiO2VyPCMwMTc3YzB$LCIlPmBePCMzMDliNGUiLCI~ZXI8I2MxZTFjYSIsIjtlcjwjMWU3ZjM1fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZjZmOGY5IiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMxOTFkMWZ$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}

$fontConfig: mat.define-typography-config(
  $font-family: 'Roboto',
);

@include mat.core();

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.6);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: white;
$light-bg-darker-3: darken($light-background, 3%);
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#191d1f, 20%);
$dark-bg-alpha-4: rgba(#191d1f, 0.04);
$dark-bg-alpha-12: rgba(#191d1f, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #191d1f;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f6f8f9, 0.04);
$light-bg-alpha-12: rgba(#f6f8f9, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-background,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Theme Config

body {
  --primary-color: #0f9adc;
  --primary-lighter-color: #c8e1f2;
  --primary-darker-color: #005088;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #0f9adc;
  --text-primary-darker-color: #{$light-primary-text};
  --primary-lighter-color-overview: #f3f3f3;
}
$mat-primary: (
  50: #e0f4fb,
  100: #b1e2f5,
  200: #7fcfee,
  300: #4fbce7,
  400: #2caee3,
  500: #07a0df,
  600: #0193d1,
  700: #0080be,
  800: #006faa,
  900: #005088,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: white,
    800: white,
    900: white,
  ),
);
$theme-primary: mat.define-palette($mat-primary, 600, 300, 700);

body {
  --accent-color: #309b4e;
  --accent-lighter-color: #268943;
  --accent-darker-color: #0d5926;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  50: #e6f4ea,
  100: #c4e4cb,
  200: #9dd3aa,
  300: #76c389,
  400: #58b670,
  500: #38aa58,
  600: #309b4e,
  700: #268943,
  800: #1d7838,
  900: #0d5926,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: white,
    800: white,
    900: white,
  ),
);
$theme-accent: mat.define-palette($mat-accent, 600, 300, 700);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #e4cacb;
  --warn-darker-color: #da0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #ba1a1a;
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  50: #ffe6e6,
  100: #ffb8b8,
  200: #ff8a8a,
  300: #ff5b5b,
  400: #ff3636,
  500: #ff0c0c,
  600: #ff0707,
  700: #ff0000,
  800: #f10000,
  900: #da0000,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: white,
    900: white,
  ),
);
$theme-warn: mat.define-palette($mat-warn, 700, 200, 900);
$theme-warn-dark-mode: mat.define-palette($mat-warn, 400, 200, 700);

$theme: (
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  ),
  density: 0,
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

$altTheme: (
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn-dark-mode,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  ),
  density: 0,
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn-dark-mode,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

:root {
  // Contants
  --br: 5px; // border-radius
  --rounded-br: 12px;

  // Variable depending on theme
  --bg: #{$light-background};
  --bg-block: #f3f3f6;
  --bg-block-overview: #e5e5e5;
  --bg-menu-icons: #cecece;

  --border-color: #c8ccd3;
  --border: 1px solid var(--border-color);

  --border-accent-color: #{$light-bg-darker-10};
  --border-accent: 1px solid var(--border-accent-color);

  --toolbar: #f9f9f9;
  --toolbar-overview: #E5e5e5;
  --hover: #{$dark-bg-alpha-12};

  --accent-text: #{$dark-accent-text};
  --focused: #{$dark-focused};
  --more-accent-text: #000000;

  --perm-false-color: #d90000;
  --perm-true-color: #00a72f;

  --warn-yellow: #d6a800;

  --bg-warn: #ffdddddd;
  --border-warn: 1px solid #ffb3b3;

  --border-success: 1px solid #b3ffb3;
  --bg-success: #ddffdddd;

  --separator: #c1c7ce;
  --separator-hov: #006492; // #004B6F;
  --block-highlight: #0f9adc;

  --mdc-theme-error: #ba1a1a;
}

body {
  background-color: var(--bg);
  color: #41474d;
}

// Theme Init
@include mat.all-component-themes($theme);

[data-theme='dark'] {
  @include mat.all-component-colors($altTheme);

  body {
    color: white;
  }

  --bg: #{$dark-background};
  --bg-block: #{$dark-bg-lighter-5};

  --border-color: #{$dark-bg-lighter-20};

  --border-accent-color: #{$dark-bg-lighter-10};

  --toolbar: #{$dark-bg-lighter-10};
  --hover: #{$light-bg-alpha-4};

  --accent-text: #{$light-accent-text};
  --focused: #{$light-focused};
  --more-accent-text: white;

  --perm-false-color: #ffafaf;
  --perm-true-color: #94dfa9;

  --warn-yellow: #ffcc00;

  --bg-warn: #2e1a1f;
  --border-warn: 1px solid #7b2f2f;

  --bg-success: #1a2e1f;
  --border-success: 1px solid #2f7b2f;

  --separator: white;
  --separator-hov: #006492; //004B6F;
  --block-highlight: #0f9adc;

  --mdc-theme-error: #ff5252;

  /*Overview Variables*/
  --bc-overview:#2c2c2c;
  --bc-leftSideMenu: #404040;
  --bc-toolbar: #404040;
}
