/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
@use '@angular/material' as mat;

.ui-a-link {
  all: unset;
  cursor: pointer;
  user-select: none;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
}

.ui-accent-text {
  color: var(--accent-text);
}

mat-hint {
  color: var(--accent-text);
}

mat-list-item.active-nav-button {
  background-color: var(--focused) !important;
  color: var(--primary-color);
  box-shadow: inset 3px 0px 0px 0px var(--primary-color);
  mat-icon {
    color: var(--primary-color) !important;
  }
}

.mat-expansion-panel-header-description {
  color: inherit !important;
}

mat-drawer-container {
  height: 100%;
}

.mdc-list-item__start {
  margin-right: 20px !important;
}

mat-nav-list {
  padding-top: 0 !important;
}

.ui-btns {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: baseline;

  & > *:only-child {
    margin-left: auto;
  }
}

.ui-flat-approval-buttons {
  border-radius: 0;
  width: 20rem;
}

.ui-video-style {
  border-radius: 10px;
  height: auto;
}

.ui-loading {
  display: grid;
  place-content: center;
  padding: 2rem;
  height: 100%;

  &.overlay {
    position: absolute;
    height: -webkit-fill-available;
    width: 100%;
  }
}

.ui-tab-label {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.mat-tab-label-active {
  &.mat-tab-label {
    opacity: 1 !important;
  }
  .ui-tab-label {
    mat-icon {
      color: var(--primary-color);
    }
  }
}

button.mat-mdc-chip-remove {
  &:hover {
    color: var(--warn-color) !important;
  }
}

.mat-mdc-tab-label-container,
.mdc-line-ripple::before {
  border-bottom: var(--border) !important;
}

.ui-filter-overlay {
  filter: brightness(0.7) grayscale(1);
  pointer-events: none;
}

mat-drawer,
mat-sidenav {
  border: none !important;
}

mat-mdc-tab-labels,
.ag-header {
  border-bottom: var(--border) !important;
}

.ag-header {
  background: transparent !important;
}

.ag-header-cell-text {
  font-size: 14px;
}

.ag-row {
  background-color: var(--bg) !important;
}

.ui-gray-overlay {
  background-color: var(--bg);
  opacity: 0.7;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ui-processing:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary-color);
  animation: spinner 0.8s linear infinite;
  background: transparent !important;
  opacity: 1 !important;
  z-index: 100;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.mdc-tooltip__surface {
  font-size: 15px !important;
  background: #3e3e3e !important;
  border: 1px solid #525252;
  box-shadow: 0px 0px 2px 0px #525252;
  opacity: 1 !important;
  text-align: center !important;
}

.ui-dialog,
.ui-dialog-window {
  max-height: 100vh;
  place-self: center;
  mat-dialog-container {
    padding: 0;
    border-radius: 0;
    .ui-dialog-top-bar {
      display: flex;
      padding-left: 0.5rem;
      min-height: 53px;
      background: var(--toolbar);
      place-items: center;
      border-bottom: var(--border);
      &[cdkDrag] {
        cursor: move;
      }
      .header {
        display: flex;
        gap: 1rem;
        place-items: center;
        font-size: 16px;
        padding-left: 0.5rem;
        font-weight: bold;
        overflow: auto;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 1rem;
        }
      }
      .grow {
        flex: 1;
      }
    }
  }
}

input[matInput] {
  margin-top: 4px;
}

mat-drawer-container {
  background-color: var(--bg);
}

.mat-drawer-container,
.mat-drawer {
  color: inherit;
}

mat-drawer {
  padding-right: 1rem;
  .ui-drawer-cont {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    max-width: 300px;
    background-color: var(--bg-block);
    margin: 1rem 0;
    border-radius: 12px;
    .box {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding: 1rem;
      .box-header {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 0.5rem;
        font-size: 18px;
        font-weight: bold;
        .box-subheader {
          font-size: 14px;
          font-weight: normal;
          color: var(--accent-text);
        }
      }
    }
  }
}

.ui-dialog-window {
  .mat-mdc-dialog-surface {
    resize: both;
    border-radius: var(--br);
    border: var(--border);
  }
}

.ui-search-header {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  mat-form-field {
    flex: 1;
    overflow: hidden;
    width: 100%;
    border-radius: 100px;
    height: 48px;
    background-color: var(--bg-block);
    .mat-mdc-text-field-wrapper {
      border-radius: 0;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mat-mdc-text-field-wrapper {
      padding: 0 6px;
    }
    .mdc-line-ripple {
      display: none;
    }
    .mat-mdc-form-field-flex {
      height: 48px;
    }
    input {
      top: -4px;
      position: relative;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }
}

.mdc-form-field {
  label {
    width: max-content;
    padding-left: 0.25rem !important;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: flex;
  max-width: 325px;
}

.mat-mdc-form-field-error-wrapper {
  position: relative !important;
}

ag-grid-angular {
  flex: 1;
  .ag-root-wrapper {
    border: none !important;
  }
  .ag-cell {
    display: grid;
    align-items: center;
    cursor: pointer;
  }
}

mat-paginator {
  border-top: var(--border);
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.ui-location-td {
  width: 50% !important;
}

.ui-location-drag-handle-cont {
  vertical-align: middle;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* width, height */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #797979;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #595959;
}

.org-account-number {
  margin-block-end: 1rem;
}

.auth-button {
  border-radius: 100px !important;
  display: block !important;
  margin: auto;
  width: 225px;
}

.mdc-button {
  border-radius: 100px !important;
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}

mat-label {
  font-size: 16px;
}

.dialog-button,
.buttons > button {
  color: #0195da;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding-right: 1rem;
}

.dialog-button:disabled {
  color: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;
}

.dialog-button-wrapper {
  display: flex;
  place-content: flex-end;
  align-self: center;
  gap: 10px;
  padding: 1rem 0;
}

.dialog-header > span:first-of-type {
  font-size: 24px;
  padding: 24px 8px 16px 0px;
  font-weight: 400;
}

.ui-dialog-top-bar {
  border-bottom: none !important;
  background-color: transparent !important;
}

.dialog-container {
  padding: 0px 16px 5px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 28px !important;
  --mdc-dialog-container-color: #e7e8eb;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  font-size: 16px;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error);
}

.mat-mdc-select-value {
  font-size: 16px;
}

[data-theme='dark'] {
  .dialog-button:disabled {
    color: #919191;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    --mdc-dialog-container-color: #242a2d;
  }
}

midas-vjs-player {
  display: block;
  border-radius: 10px;
  & > :first-child {
    border-radius: 10px;
    & > :first-child {
      border-radius: 10px;
    }
  }
}

.video-container {
  position: relative;
  text-align: center;
  img {
    border-radius: 10px;
  }
}

.video-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.ui-colored-label {
  white-space: nowrap;
  border-radius: var(--br);
  &.primary {
    background-color: var(--primary-lighter-color);
    box-shadow: -5px 0 0 6px var(--primary-lighter-color), 5px 0 0 6px var(--primary-lighter-color);
    color: var(--text-primary-lighter-color);
  }
  &.warn {
    background-color: var(--warn-lighter-color);
    box-shadow: -5px 0 0 6px var(--warn-lighter-color), 5px 0 0 6px var(--warn-lighter-color);
    color: var(--text-warn-lighter-color);
  }
}
