/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
@use 'ag-grid-community/styles' as ag;

@import '@angular/cdk/overlay-prebuilt.css';
@import 'mat';
@import 'ui';

@include ag.grid-styles(
  (
    theme: alpine,
  )
);
